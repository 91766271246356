import router from "@/router";

const IDS = process.env.VUE_APP_HYBRID_EXPERIMENTS;
const HYBRID_EXP = (IDS ? IDS.split(",") : null) ?? ["SEARCH1289#b"];

export function queryParamsToQueryString(queryParams) {
  const e = encodeURIComponent;
  return Object.keys(queryParams)
    .map((key) => `${e(key)}=${e(queryParams[key])}`)
    .join("&");
}

/**
 * Redirects user to the correct entry page based on the query parameters.
 * Some experiment variants will be redirected to hybrid
 */
export function toEntryPage(queryParams, forcePath = undefined) {
  const exp = queryParams?.exp ?? "";
  const path =
    (forcePath ?? HYBRID_EXP.includes(exp ?? "")) ? "/search" : "/buy";

  const qString = queryParamsToQueryString(queryParams);
  const addition = qString ? `?${qString}` : "";

  router.replace(`${path}${addition}`);
}
