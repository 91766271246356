<template>
  <div class="flex w-full flex-wrap items-center gap-2">
    <input
      ref="searchInput"
      v-model="localQueryText"
      type="text"
      :placeholder="placeholder"
      :class="[
        'h-10 w-full max-w-96 grow px-2 text-base font-normal leading-4.5',
        'shrink-0 md:mr-3 md:h-14 md:w-1/2 md:max-w-none md:grow-0 md:px-4 md:text-lg',
        'rounded border border-magic-700 text-grey-600 focus:ring-0',
      ]"
      @keyup.enter="onEnter"
    />
    <BaseButton
      variant="secondary"
      class="shrink-0 !rounded-full transition-all md:!h-12 md:!px-6"
      :href="`/buy` + (localQueryText ? `?query=${localQueryText}` : ``)"
    >
      <SwitchChatIcon class="size-5 text-magic-950" />
      <span class="text-base font-normal text-grey-700 md:text-lg">
        {{ $t("magic_search_two_point.home_page.btn.chat") }}
      </span>
    </BaseButton>
    <BaseButton
      variant="primary"
      class="shrink-0 !rounded-full transition-all md:!h-12 md:!px-6"
      :href="`/search` + (localQueryText ? `?q=${localQueryText}` : ``)"
    >
      <MagicSearchIcon class="size-5 text-white" />
      <span class="text-base font-normal text-white md:text-lg">
        {{ $t("magic_search_two_point.home_page.btn.search") }}
      </span>
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import MagicSearchIcon from "@/components/ui/icons/MagicSearchIcon.vue";
import SwitchChatIcon from "@/components/ui/icons/SwitchChatIcon.vue";

export default {
  name: "HomeSearchBar",
  components: {
    BaseButton,
    MagicSearchIcon,
    SwitchChatIcon,
  },
  data() {
    return {
      localQueryText: "",
    };
  },
  computed: {
    placeholder() {
      const maxVersions = 9;
      const addition = maxVersions
        ? `.${Math.floor(Math.random() * maxVersions)}`
        : "";
      const fullLabel = `search.search_bar.input_placeholder${addition}`;
      return this.$t(fullLabel);
    },
  },
  methods: {
    onEnter() {
      this.$router.push({ name: "Search", query: { q: this.localQueryText } });
    },
  },
};
</script>
