<template>
  <div
    :class="[
      'absolute right-0 top-0 -z-10',
      'h-1/5 w-screen rounded-full bg-teal-500 opacity-15 blur-3xl',
    ]"
  ></div>
</template>
<script>
export default {
  name: "HomeColorArea",
};
</script>
