import store from "@/store";
import { getNinjaConfig } from "./ninjaConfig";
import { getClientSessionParams } from "@/auth/magic_auth";
import logger from "@/services/logger";
import router from "@/router";

const NINJA_URL = "https://ninja.data.olxcdn.com/ninja-cee.js";

class TrackingService {
  constructor() {
    if (TrackingService.instance) {
      return TrackingService.instance;
    }
    this.initNinjaTracking();
    this.state = {
      count: 1,
    };
    TrackingService.instance = this;
    Object.freeze(this);
  }

  initNinjaTracking() {
    window.dataLayer = window.dataLayer || [];
    try {
      var ninjaTag = document.getElementById("ninja-script-tag");
      if (!ninjaTag) {
        (function () {
          "use strict";
          window.configTracking = getNinjaConfig();
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.id = "ninja-script-tag";
          script.async = true;
          script.src = NINJA_URL;
          (
            document.getElementsByTagName("head")[0] ||
            document.getElementsByTagName("body")[0]
          ).appendChild(script);
        })();
      }
    } catch (error) {
      // @TODO newrelic capture exception here when newrelic is ready
      logger.error("Error initializing Ninja tracking:", error);
    }
  }

  capture(eventType, eventData) {
    const { event_flow, magic_version, ...restEventData } = eventData;
    const { conversation_id, user_id, user_uuid, hybrid_search_id } =
      this._commonData();
    const event = {
      ...restEventData,
      event_type: eventType,
      touch_point_page: "magic",
      user_id,
      user_uuid,
      magic_version,
    };

    if (
      event_flow === "product" &&
      conversation_id &&
      magic_version !== "search"
    ) {
      event.conversation_id = conversation_id;
    }

    if (magic_version === "search") {
      event.conversation_id = hybrid_search_id;
    }

    // logger.log(event, "__final__tracking__event");

    // Ninja tracking implementation

    window.dataLayer.push({
      cleanup: function () {
        window.dataLayer.push(event);
      },
    });
  }

  trackPage(eventData) {
    const { event_name, ad_position, magic_version, ...params } = eventData;

    const event = {
      ...params,
      magic_version,
      trackPage: event_name,
    };

    // Conditionally add ad_position if event_name is "magic_item_info"
    if (event_name === "magic_item_info") {
      event.ad_position = ad_position;
    }

    if (event_name === "magic_search_results") {
      event.result_count = this.state.count;
      this.state.count += 1;
    }

    if (
      router.currentRoute.value.path === "/search" &&
      magic_version !== "search"
    )
      logger.warn(event, "__hybrid__tracking__page");

    this.capture("pv", event);
  }

  trackEvent(eventType, eventData) {
    const {
      event_name,
      magic_card_type,
      ad_id,
      magic_version,
      magic_ad_source,
      ...params
    } = eventData;

    // Remove ad_position from params if it exists
    delete params.ad_position;

    const additionalParams = {};

    // Conditionally add magic_card_type if event_name is "magic_search_default_prompt"
    if (event_name === "magic_search_default_prompt") {
      additionalParams.magic_card_type = magic_card_type;
    }

    // Conditionally add ad_id if event_name is "magic_ad_link"
    if (event_name === "magic_ad_link") {
      additionalParams.ad_id = ad_id;

      // Log error if magic_ad_source is undefined
      if (typeof magic_ad_source === "undefined") {
        logger.error(
          { eventType, eventData },
          "trackEvent: 'magic_ad_source' is undefined for event_name 'magic_ad_link'",
        );
      }
    }

    if (
      router.currentRoute.value.path === "/search" &&
      magic_version !== "search"
    ) {
      logger.warn("Unexpected event name for magic_version:search event", {
        ...eventData,
        event_type: eventType,
      });
    }

    this.capture(eventType, {
      ...params,
      ...additionalParams,
      magic_ad_source,
      magic_version,
      trackEvent: event_name,
    });
  }

  trackLinkEvent(trackLinkEvent, linkCallBack) {
    this.capture("click", { trackLinkEvent, linkCallBack });
  }

  _commonData() {
    const userStore = store.state?.user;
    const data = {
      user_id: userStore?.user?.id,
      user_uuid: userStore?.user?.uuid,
      conversation_id: store.state?.messages?.sessionId,
      hybrid_search_id: store.state?.search?.hybridSearch?.id,
    };

    const { client_s, client_sl } = getClientSessionParams();
    if (client_s) data.client_s = client_s;
    if (client_sl) data.client_sl = client_sl;

    return data;
  }

  // Laquesis SDK integration methods
  getLaquesisVariant(experimentName) {
    if (window.getLaquesisVariant) {
      try {
        return window.getLaquesisVariant(experimentName);
      } catch (error) {
        logger.error("Error getting Laquesis variant:", error);
        return null;
      }
    } else {
      logger.error("Laquesis SDK is not loaded.");
      return null;
    }
  }

  isFeatureEnabled(flagName) {
    if (window.isFeatureEnabled) {
      try {
        return window.isFeatureEnabled(flagName);
      } catch (error) {
        logger.error("Error checking if feature is enabled:", error);
        return null;
      }
    } else {
      logger.error("Laquesis SDK is not loaded.");
      return null;
    }
  }

  showSurvey(surveyId) {
    if (window.laquesisShowSurvey) {
      try {
        window.laquesisShowSurvey(surveyId);
      } catch (error) {
        logger.error("Error showing Laquesis survey:", error);
      }
    } else {
      logger.error("Laquesis SDK is not loaded.");
    }
  }
}

const instance = new TrackingService();
export default instance;
