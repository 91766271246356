<template>
  <EntryView
    :loading="loading"
    :error="error"
    @complete-entry="completeEntry"
  />
</template>

<script>
import { completeEntryResponse } from "@/auth/magic_auth";
import { MAGIC_WEB_ENTRY } from "@/constants/eventsConstants";
import { completeEntryWeb } from "@/services/AuthService";
import TrackingService from "@/services/TrackingService";
import Cookies from "js-cookie";
import EntryView from "./EntryView.vue";
import { toEntryPage } from "./utils";

const COOKIE_NAME = "X-Magic-Onboarded";

export default {
  name: "Entry",
  components: {
    EntryView,
  },
  data() {
    return {
      loading: true,
      error: undefined,
    };
  },
  mounted() {
    this.$nextTick(() => this.completeEntry());
  },
  methods: {
    async completeEntry() {
      try {
        this.loading = true;
        this.error = undefined;
        const { key, ...params } = this.$route.query;

        const k = Cookies.get(COOKIE_NAME) ?? key;
        const resp = await completeEntryWeb(k);

        await completeEntryResponse(resp);
        TrackingService.trackPage(MAGIC_WEB_ENTRY);

        this.$nextTick(() => toEntryPage(params));
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
