<template>
  <BaseButton
    v-if="isSearchRoute"
    v-track:click="$EVENTS.HYBRID.MAGIC_GO_TO_CHAT"
    :class="['size-6', switching && 'animate-pulse cursor-wait opacity-60']"
    variant="icon"
    @click.stop="switchToChat"
  >
    <SwitchChatIcon />
  </BaseButton>
</template>

<script>
import logger from "@/services/logger";
import { sanitizeMessage } from "@/utils/string";
import { mapActions, mapGetters } from "vuex";
import { TAG_SEPARATOR } from "../search/utils/search-entry";
import BaseButton from "../ui/BaseButton.vue";
import SwitchChatIcon from "../ui/icons/SwitchChatIcon.vue";
export default {
  name: "SwitchToChatButton",
  components: {
    SwitchChatIcon,
    BaseButton,
  },
  data: function () {
    return {
      switching: false,
    };
  },
  computed: {
    ...mapGetters("search", ["originProduct"]),
    isSearchRoute() {
      return this.$route.path.startsWith("/search");
    },
  },
  watch: {
    $route() {
      this.switching = false;
    },
  },
  methods: {
    ...mapActions("messages", [
      "createNewSession",
      "findSimilar",
      "sendMessage",
    ]),
    async switchToChat() {
      this.switching = true;

      const { query } = this.$route;
      const { q, tags, product_id, image, ...rest } = query;

      // Clear chat session
      try {
        await this.createNewSession();
      } catch (e) {
        logger.error("Failed to create new chat session", e);
      }

      // Preapare chat if needed
      if (product_id) {
        this.findSimilar(this.originProduct);
      } else if (image) {
        this._prepareChatForVisualSearch(q, image);
      }

      // Prepare link and switch
      const params = this._getQueryParamsForChatLink(q, tags, rest);
      this.$router.push({ name: "Buy", query: params });
    },
    _prepareChatForVisualSearch(query, image) {
      const defaultPrompt = this.$t("search.image_placeholder_text");
      const url = atob(image);
      this.sendMessage({
        text: sanitizeMessage(query || defaultPrompt),
        filesUrls: [url],
        isUser: true,
      });
    },
    _getQueryParamsForChatLink(q, tags = "", rest = {}) {
      const params = {};
      if (q) params["query"] = q;
      tags?.split(TAG_SEPARATOR)?.forEach((tag) => {
        const parts = tag.split(":");
        params[parts[0]] = parts[1] ?? "true";
      });
      Object.entries(rest).forEach(([key, value]) => {
        if (key === "encoded_filters") {
          params["rawData"] = value;
        } else {
          params[key] = value;
        }
      });
      return params;
    },
  },
};
</script>
