<template>
  <div class="my-2 w-full border-y border-grey-400 p-4">
    <div class="flex w-full flex-col gap-2 overflow-x-auto">
      <div
        v-for="n in PRODUCTS_SKELETON_COUNT"
        :key="n"
        class="flex w-full rounded-lg border border-gray-200 bg-white"
      >
        <div class="relative size-36">
          <div class="size-36 animate-pulse rounded-l-lg bg-gray-300"></div>
          <div
            :class="[
              'absolute left-2 top-2 h-6 w-10 animate-pulse rounded',
              'bg-gray-300 px-2 py-1 text-xs font-bold text-white',
            ]"
          ></div>
        </div>
        <div class="flex w-full flex-col justify-between px-3 py-2">
          <div class="">
            <div class="flex justify-between">
              <div class="h-4 w-3/4 animate-pulse rounded bg-gray-300"></div>
              <div class="size-6 animate-pulse rounded bg-gray-300"></div>
            </div>
            <div class="mt-2 h-4 w-1/2 animate-pulse rounded bg-gray-300"></div>
          </div>
          <div class="flex flex-col justify-between gap-4">
            <div class="h-4 w-1/4 animate-pulse rounded bg-gray-300"></div>
            <div class="flex justify-between">
              <div class="h-4 w-1/2 animate-pulse rounded bg-gray-300"></div>
              <div class="h-4 w-16 animate-pulse rounded bg-gray-300"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSkeleton",
  data() {
    return {
      PRODUCTS_SKELETON_COUNT: 20,
    };
  },
};
</script>
