<template>
  <BaseButton
    variant="primary-dark"
    :floating="true"
    :disabled="!refineButtonEnabled"
    @click="onShowRefine"
  >
    <RefineIcon class="size-5" />
    <span class="text-sm font-medium">{{
      $t("magic_search_two_point.action_bar_button_text.filter")
    }}</span>
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import { mapGetters, mapMutations } from "vuex";
import RefineIcon from "./RefineIcon.vue";

export default {
  name: "RefineButton",
  components: {
    BaseButton,
    RefineIcon,
  },
  computed: {
    ...mapGetters("search", ["refineButtonEnabled"]),
  },
  methods: {
    ...mapMutations("hybridActions", ["SET_REFINE_MODAL_OPEN"]),
    onShowRefine() {
      if (!this.refineButtonEnabled) return;
      this.SET_REFINE_MODAL_OPEN(true);
    },
  },
};
</script>
