<template>
  <span
    v-if="condition"
    class="h-7 rounded-md bg-gray-100 px-2 py-1.5 text-sm capitalize text-grey-700"
    :class="className"
  >
    {{ label }}
  </span>
</template>

<script>
export default {
  name: "ProductCardCondition",
  props: {
    condition: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
  },
  computed: {
    label: function () {
      switch (this.condition?.toLowerCase()?.trim()) {
        case "new":
          return this.$t("product.condition_new");
        case "used":
          return this.$t("product.condition_used");
        default:
          return this.condition;
      }
    },
  },
};
</script>
