<template>
  <div
    class="full-page-content container mx-auto flex flex-col items-center justify-center gap-4"
  >
    <template v-if="loading">
      <h1 class="text-3xl font-bold">
        {{ $t("entry.redirect_in_progress_title") }}
      </h1>
      <p class="text-center text-xl">
        {{ $t("entry.redirect_in_progress_text") }}
      </p>
      <Loader />
    </template>

    <template v-if="error">
      <h1 class="text-3xl font-bold">
        {{ $t(`entry.redirect_error_${status}_title`) }}
      </h1>
      <p class="text-center text-lg">
        {{ $t(`entry.redirect_error_${status}_text`) }}
      </p>
      <RouterLink to="/">
        <BaseButton @click="onCompleteEntry">
          {{ $t("entry.back_to_home") }}
        </BaseButton>
      </RouterLink>
    </template>
  </div>
</template>

<script>
import Loader from "@/components/ui/Loader.vue";
import { RouterLink } from "vue-router";
import BaseButton from "../ui/BaseButton.vue";

export default {
  name: "MobileEntry",
  components: {
    Loader,
    BaseButton,
    RouterLink,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Object,
      default: undefined,
    },
  },
  emits: ["complete-entry"],
  computed: {
    status() {
      if (!this.error) return undefined;
      if (!this.error.status) return 500;

      const expected = [401, 403, 500];
      return expected.includes(parseInt(this.error.status, 10))
        ? this.error.status
        : 500;
    },
  },
  methods: {
    onCompleteEntry() {
      this.$emit("complete-entry");
    },
  },
};
</script>
