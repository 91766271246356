<template>
  <div class="overflow-hidden">
    <Transition name="slide-down" duration="300">
      <div
        v-if="product"
        :key="product?.id"
        class="flex w-full flex-wrap transition-all duration-300"
      >
        <img
          class="!m-0 aspect-square w-full !max-w-96 grow rounded-md object-cover md:w-1/2"
          :src="product.image"
          :alt="product.title"
        />
        <div class="flex w-full flex-col font-olx md:w-1/2 md:px-4">
          <h2 class="mt-4 w-full !text-2xl font-bold md:mt-0">
            {{ product.title }}
          </h2>
          <div class="mt-4 flex items-center justify-start">
            <span class="text-3xl">{{ product.price }}</span>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  MAGIC_ADD_FAVOURITE,
  MAGIC_REMOVE_FAVOURITE,
} from "@/constants/eventsConstants";

export default {
  name: "MarkdownProductHeader",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters("favourites", ["favourites"]),
    product() {
      try {
        return JSON.parse(tryToFixJson(this.text));
      } catch {
        return null;
      }
    },
  },
  methods: {
    ...mapActions("favourites", ["addFavourite", "removeFavourite"]),
    isFavourite(product) {
      return this.favourites?.some(
        (favourite) => favourite.url === itemUrl(product),
      );
    },
    toggleFavourite(product) {
      if (this.isFavourite(product)) {
        this.$tracking.trackEvent("click", MAGIC_REMOVE_FAVOURITE);
        this.removeFavourite(itemUrl(product));
      } else {
        this.$tracking.trackEvent("click", MAGIC_ADD_FAVOURITE);
        this.addFavourite({
          ...product,
          url: itemUrl(product),
          photos: [product.image],
          params: {
            price: parseFloat(product.price.replace(/[^0-9.-]+/g, "")),
            state: " ",
          },
        });
      }
    },
  },
};

function itemUrl(item) {
  return `/buy/${item.id}`;
}

function tryToFixJson(text) {
  if (count(text, "{") === count(text, "}")) return text;
  const s = text.replace(/ /g, "").repkace(/\\n/g, "");
  if (count(s, '"') % 2 === 0) return `${s}`;
  else return `${s}"}"`;
}

function count(str, c) {
  let count = 0;
  for (let i = 0; i < str.length; i++) if (str[i] === c) count++;
  return count;
}
</script>
