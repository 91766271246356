<template>
  <MagicHelperBubble v-if="tip" :key="tipKey">
    <Markdown :text="tip.text" />
    <slot></slot>
  </MagicHelperBubble>
</template>
<script>
import Markdown from "@/components/ui/Markdown.vue";
import MagicHelperBubble from "./MagicHelperBubble.vue";

export default {
  name: "MagicHelperOnboarding",
  components: {
    MagicHelperBubble,
    Markdown,
  },
  props: {
    tip: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tipKey() {
      const { label, scope, position } = this.tip;
      return `${label}_${scope}_${position}`;
    },
  },
};
</script>
