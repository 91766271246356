const NOT_ALLOWED_VALUES = ["null", "undefined"];
const NOT_ALLOWED_KEYS = [
  "sessionId",
  "session_id",
  "exp",
  "origin_hybrid_search_id",
  "municipality",
  "county",
];

export function filterEntryQueryParams(params) {
  const clonedParams = {};

  Object.entries(params).forEach(([key, value]) => {
    const v = value?.toLowerCase?.();
    const k = key?.toLowerCase?.();

    const NV = NOT_ALLOWED_VALUES.map((a) => a.toLowerCase());
    const NK = NOT_ALLOWED_KEYS.map((a) => a.toLowerCase());

    if (!v) return;
    else if (NV.includes(v)) return;
    else if (k.startsWith("_")) return;
    else if (k.length < 3) return;
    else if (NK.includes(k)) return;

    clonedParams[key] = value;
  });
  return clonedParams;
}
