<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.714 5.47792C16.2596 8.15493 15.3424 11.578 12.6654 13.1236C9.98839 14.6692 6.56531 
      13.7519 5.01974 11.0749C3.47416 8.39793 4.39138 4.97485 7.06839 3.42927C9.7454 1.8837 13.1685 
      2.80091 14.714 5.47792ZM16.2976 4.56367C18.3481 8.11524 17.1312 12.6566 13.5797 14.7071C10.916 
      16.2449 7.69571 15.945 5.38693 14.2007L1.43733 18.1504H0V16.713L3.94952 12.7636C3.76485 12.5194 
      3.59322 12.2612 3.4362 11.9892C1.3857 8.43762 2.60256 3.89624 6.15413 1.84574C9.7057 -0.204759 
      14.2471 1.0121 16.2976 4.56367ZM5.79606 8.70563C6.77085 8.70563 7.5971 9.05354 8.30407 
      9.75942C9.01156 10.4658 9.38217 11.3124 9.42812 12.3198H10.2545C10.3004 11.3128 10.6707 10.467 
      11.3854 9.76102L11.387 9.75942C12.0933 9.05422 12.9279 8.70563 13.9036 8.70563V7.83865C13.2584 
      7.83865 12.6722 7.67956 12.1215 7.36059C11.5666 7.03034 11.1246 6.59138 10.7966 6.04206L10.7958 
      6.04064C10.4608 5.48439 10.2852 4.88183 10.2545 4.21531L9.42828 4.21231C9.37434 5.23385 9.00952 
      6.08051 8.30407 6.78487C7.5971 7.49074 6.77085 7.83866 5.79606 7.83866V8.70563Z"
    />
  </svg>
</template>
<script>
export default {
  name: "MagicSearchIcon",
};
</script>
