<!-- eslint-disable vue/max-len -->
<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="currentColor"
    >
      <g clip-path="url(#clip0_1190_5030)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.1365 3.60296C15.5876 3.15112 15.81 2.62303 15.81 2H16.3641C16.3641 2.62303 16.5865 3.15112 17.0376 3.60296C17.4878 4.05384 18.0289 4.28702 18.6818 4.32149L18.6799 4.84954C18.2539 4.8692 17.8688 4.98142 17.5133 5.19551L17.5124 5.19605C17.1613 5.40566 16.8807 5.68814 16.6696 6.04281C16.4658 6.39482 16.3641 6.76945 16.3641 7.18182H15.81C15.81 6.55819 15.5872 6.02483 15.1365 5.57341L15.1354 5.57237C14.6842 5.11557 14.1436 4.87891 13.5 4.84954V4.32139C14.1439 4.29202 14.685 4.05515 15.1365 3.60296ZM7.64148 12.8585V12.8658H8.44783V13.8658H7.64148V17H15.5V9.14148H12.3658V9.94783H11.3658V9.14148H11.3585V9.13477H10.5527V8.13477H11.3585V5H3.5V12.8585H6.63477V12.0527H7.63477V12.8585H7.64148ZM2.5 4.49518C2.5 4.2217 2.7217 4 2.99518 4H11.8633C12.1368 4 12.3585 4.2217 12.3585 4.49518V7.14148V8.14148H13.3585H16.0048C16.2783 8.14148 16.5 8.36318 16.5 8.63666V17.5048C16.5 17.7783 16.2783 18 16.0048 18H7.13666C6.86318 18 6.64148 17.7783 6.64148 17.5048V14.8585V13.8585H5.64148H2.99518C2.7217 13.8585 2.5 13.6368 2.5 13.3633V4.49518ZM6.63477 8.89537C6.63477 8.4753 6.9753 8.13477 7.39537 8.13477H8.44783V9.13477H7.63477V9.94783H6.63477V8.89537ZM12.3658 12.0527V13.1052C12.3658 13.5253 12.0253 13.8658 11.6052 13.8658H10.5527V12.8658H11.3658V12.0527H12.3658Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1190_5030">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
