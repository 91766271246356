<template>
  <div
    class="full-page-content container mx-auto flex flex-col gap-4 p-0 md:overflow-hidden md:p-4"
  >
    <NotificationBar class="w-full" />

    <div class="flex grow flex-col gap-4 md:flex-row md:overflow-hidden">
      <div class="flex w-full grow flex-col md:gap-2 md:rounded-lg">
        <ConversationDisplay
          class="grow pb-20 md:rounded-lg md:pb-0 md:shadow-sm lg:overflow-y-auto"
          :is-focused="isFocused"
          translate="no"
          @example-selected="onCardSelected($event)"
        />
        <MessageInput
          :prompt-text="selectedCard?.prompt_text"
          :prompt-key="selectedCard?.prompt_key"
          :event-type="selectedCard?.type"
          translate="no"
          :class="[
            'dropdown-trigger fixed bottom-0 w-full px-0 transition duration-150',
            'ease-in-out sm:px-4 md:relative md:bg-gray-100 md:px-0',
            selected.length < 1 ? 'block' : 'hidden sm:block',
          ]"
          @focus="isFocused = true"
          @blur="isFocused = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  MAGIC_LOADED,
  MAGIC_LOADED_CONTEXTUAL,
  MAGIC_NEW_CONVERSATION,
} from "@/constants/eventsConstants";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import TrackingService from "../services/TrackingService";
import ConversationDisplay from "./buy/ConversationDisplay.vue";
import MessageInput from "./buy/MessageInput.vue";
import NotificationBar from "./NotificationBar.vue";
import { filterEntryQueryParams } from "@/utils/entry-point";
import { sentenceCase } from "@/utils/string";
import { omit } from "@/utils/object";

export default {
  name: "BuyPage",
  components: {
    MessageInput,
    ConversationDisplay,
    NotificationBar,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedCard: null,
      isFocused: false,
      MAGIC_NEW_CONVERSATION,
    };
  },
  computed: {
    ...mapState("messages", ["messages", "sessionId", "new_session"]),
    ...mapGetters("selected", ["selected"]),
    ...mapState({
      screenWidth: (state) => state.windowSize.windowWidth,
    }),
  },
  watch: {
    sessionId: {
      immediate: true,
      handler(newSessionId) {
        const { sessionId, ...rest } = this.$route.query;
        const existingId = sessionId ?? null;
        const query = newSessionId ? { sessionId: newSessionId, ...rest } : {};

        if (existingId !== newSessionId) {
          this.$router.replace({ path: "/buy", query });
        }
      },
    },
  },
  mounted() {
    this.handleContextualEntry();
  },
  methods: {
    ...mapActions("messages", ["createNewSession", "sendStreamingTask"]),
    ...mapMutations("messages", ["SEND_MESSAGE"]),
    onCardSelected(selectedCard) {
      this.selectedCard = {
        prompt_text: "",
        prompt_key: "",
        type: "",
      };

      this.$nextTick(() => {
        this.selectedCard = selectedCard;
        this.$store.commit("messages/SET_NEW_SESSION", true);
      });
    },
    async handleContextualEntry() {
      const params = filterEntryQueryParams(this.$route.query);
      if (Object.keys(params).length > 0) {
        this._insertContextualMessages(params);
        TrackingService.trackPage({
          ...MAGIC_LOADED_CONTEXTUAL,
          ...getContextualEventParams(params),
        });
        try {
          await this.sendStreamingTask({
            messageType: "contextual_entry",
            parameters: params,
            isUser: true,
          });
        } catch {
          /* noop */
        } finally {
          const query = this.sessionId
            ? { sessionId: this.sessionId }
            : undefined;
          this.$router.replace({ path: "/buy", query });
        }
      } else {
        TrackingService.trackPage(MAGIC_LOADED);
      }
    },
    _insertContextualMessages(params) {
      const { query, ...rest } = params;

      const allowed = omit(rest, ["rawData"]);
      const s = sentenceCase;
      const paramsString = Object.entries(allowed)
        .map(([key, value]) => `**${s(key)}:** ${s(value)}`)
        .join("<br/>");

      const userMessageKey = `conversation.contextual_entry_user_message_${query ? "with_query" : "without_query"}`;
      this.SEND_MESSAGE({
        text: `${this.$t(userMessageKey, { query })}<br/>${paramsString}`,
        isUser: true,
        isContextualEntryMessage: true,
      });
      this.SEND_MESSAGE({
        text: this.$t("conversation.contextual_entry_bot_message"),
        isUser: false,
        isContextualEntryMessage: true,
      });
    },
  },
};

function getContextualEventParams(queryParams) {
  const { category, location, region, city, min_price, max_price, ...rest } =
    queryParams;

  const eventParams = {
    category,
    location: [location, region, city].filter(Boolean).join(", "),
    min_price,
    max_price,
    other_parameters_count: Object.keys(rest).length,
  };
  return eventParams;
}
</script>
