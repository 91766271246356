import logger from "@/services/logger";
import {
  postMagicCompare,
  postRefineHybridSearch,
} from "@/services/SearchServiceV2";
import { moveToNewSearch } from "@/store/utils/hybrid";

const defaultState = () => ({
  selectedAdIds: [],
  refineModalOpen: false,
  refineSuggestions: null,
  refineLoading: false,
  refineError: null,
  compareModalOpen: false,
  compareLoading: false,
  compareResult: null,
  compareError: null,
});

const state = defaultState();

const getters = {
  refineSuggestions: (state) => state.refineSuggestions,
  refineModalOpen: (state) => state.refineModalOpen,
  compareModalOpen: (state) => state.compareModalOpen,
  selectedAdIds: (state) => state.selectedAdIds,
  compareResult: (state) => state.compareResult,
  compareLoading: (state) => state.compareLoading,
  compareError: (state) => state.compareError,
  refineLoading: (state) => state.refineLoading,
  refineError: (state) => state.refineError,
};

const actions = {
  async refine({ commit, rootGetters }, { message, ...rest }) {
    try {
      const searchId = rootGetters["search/searchId"];
      if (!searchId || !message) return;

      commit("SET_REFINE_LOADING", true);
      commit("SET_REFINE_ERROR", null);

      const language = rootGetters["settings/selectedLocale"] ?? "PL";
      const response = await postRefineHybridSearch({
        hybrid_search_id: searchId,
        message,
        language,
        ...rest,
      });
      const { new_query, new_tags, image_link } = (response ?? {}).data;
      moveToNewSearch({
        newQuery: new_query,
        newTags: new_tags,
        newImage: image_link,
        origin_hybrid_search_id: searchId,
      });
      commit("search/CLEAR_FOR_NEW_SEARCH", null, { root: true });
    } catch (e) {
      logger.error("Refine error:", e);
      commit("SET_REFINE_ERROR", e);
    } finally {
      this.loading = false;
      commit("SET_REFINE_LOADING", false);
      commit("SET_REFINE_MODAL_OPEN", false);
    }
  },
  async compare({ commit, rootGetters, getters }) {
    try {
      const product_ids = getters["selectedAdIds"];
      const searchId = rootGetters["search/searchId"];
      if (!searchId || !product_ids || !product_ids.length) return;

      commit("SET_COMPARE_LOADING", true);
      commit("SET_COMPARE_ERROR", null);
      commit("SET_COMPARE_RESULT", null);

      const language = rootGetters["settings/selectedLocale"] ?? "PL";

      await postMagicCompare(
        {
          hybrid_search_id: searchId,
          product_ids,
          language,
        },
        (message) => {
          commit("APPEND_COMPARE_RESULT", message);
        },
      );
    } catch (error) {
      logger.error(error);
      commit("SET_COMPARE_ERROR", error);
    } finally {
      commit("SET_COMPARE_LOADING", false);
    }
  },
};

const mutations = {
  SET_REFINE_MODAL_OPEN: (state, value) => (state.refineModalOpen = value),
  SET_COMPARE_MODAL_OPEN: (state, value) => (state.compareModalOpen = value),
  CLEAR_SELECTED_AD_IDS: (state) => (state.selectedAdIds = []),
  ADD_SELECTED_AD_ID: (state, id) =>
    (state.selectedAdIds = unique([...state.selectedAdIds, id])),
  REMOVE_SELECTED_AD_ID: (state, id) =>
    (state.selectedAdIds = state.selectedAdIds.filter((i) => i !== id)),
  CLEAR_FOR_NEW_SEARCH: (state) => Object.assign(state, defaultState()),
  SET_REFINE_SUGGESTIONS: (state, suggestions) => {
    state.refineSuggestions = suggestions;
  },
  SET_REFINE_LOADING: (state, loading) => {
    state.refineLoading = loading;
  },
  SET_REFINE_ERROR: (state, error) => {
    state.refineError = error;
  },
  SET_COMPARE_LOADING: (state, loading) => {
    state.compareLoading = loading;
  },
  SET_COMPARE_RESULT: (state, result) => {
    state.compareResult = result;
  },
  APPEND_COMPARE_RESULT: (state, result) => {
    state.compareResult = (state.compareResult ?? "") + result;
  },
  SET_COMPARE_ERROR: (state, error) => {
    state.compareError = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function unique(arr) {
  return Array.from(new Set(arr));
}
