import { GROUP_EVERY_X_PRODUCTS } from "../utils/hybrid";
import { TIP_SCOPE, tipFactory } from "../utils/hybrid-onboarding";

const state = {
  input_box: {
    current_query: "",
    current_image: "",
  },
  searches: [],
};

const Nth = (n) => GROUP_EVERY_X_PRODUCTS * n + n - 1;

const getters = {
  mainTip: (state, getters, rootState, rootGetters) => {
    const hasResults = !!rootGetters["search/searchId"];
    if (hasResults) return undefined;

    const { current_query, current_image } = state.input_box;
    if (current_image)
      return tipFactory({
        label: "image",
        scope: TIP_SCOPE.MAIN,
        maxVersions: 10,
        highlight: "submit",
      });

    if (current_query) return undefined;

    return tipFactory({
      label: "greeting",
      scope: TIP_SCOPE.MAIN,
      maxVersions: 4,
    });
  },
  mainHighlight: (state, getters) => {
    return getters["mainTip"]?.highlight;
  },
  resultListTips: (state) => {
    const tips = [];
    const lastSearch = getLastSearch(state);
    if (!lastSearch) return tips;

    if (lastSearch.product_count > 0) {
      tips.push(
        tipFactory({
          label: "groups",
          scope: TIP_SCOPE.GROUPS,
          position: Nth(1),
          maxVersions: 10,
          needsGroup: true,
        }),
      );
      tips.push(
        tipFactory({
          label: "refine",
          scope: TIP_SCOPE.GROUPS,
          position: Nth(2),
          maxVersions: 10,
          needsGroup: true,
          indicateAction: "refine",
        }),
      );
      tips.push(
        tipFactory({
          label: "compare",
          scope: TIP_SCOPE.GROUPS,
          position: Nth(3),
          maxVersions: 10,
          indicateAction: "compare",
          needsGroup: true,
        }),
      );
    }

    return tips;
  },
};

const findSearchById = (searchArray, id) => {
  return searchArray?.find((search) => search.id === id);
};

const getLastSearch = (state) => {
  return state.searches[state.searches.length - 1];
};

const createSearchObject = (id) => ({
  id,
  product_count: 0,
  p_group: [],
  q_group: [],
  product_click_count: 0,
  group_product_click_count: 0,
  sort_click_count: 0,
});

const mutations = {
  SET_NEW_SEARCH_CREATED(state, { hybrid_search_id }) {
    const existingSearch = findSearchById(state.searches, hybrid_search_id);
    if (!existingSearch) {
      state.searches.push(createSearchObject(hybrid_search_id));
    }
  },
  SET_NEW_SEARCH_PRODUCTS(state, { hybrid_search_id, count }) {
    const search = findSearchById(state.searches, hybrid_search_id);
    if (search) {
      search.product_count = count;
    }
  },
  SET_NEW_SEARCH_P_GROUP(state, { hybrid_search_id, p_group }) {
    const search = findSearchById(state.searches, hybrid_search_id);
    if (search) {
      search.p_group = p_group.map((g) => ({
        title: g.title,
        count: g.item_ids?.length,
      }));
    }
  },
  SET_QUERY_BASED_GROUPS_SUCCESS(state, { hybrid_search_id, q_group }) {
    const search = findSearchById(state.searches, hybrid_search_id);
    const gs = q_group.map((g) => ({
      title: g.title,
      count: g.items?.length,
    }));
    if (search) {
      search.q_group = gs;
    } else {
      state.searches.push({
        ...createSearchObject(hybrid_search_id),
        q_group: gs,
      });
    }
  },
  USER_CLICKED_PRODUCT(state, { type }) {
    const lastSearch = getLastSearch(state);
    if (lastSearch) {
      if (type === "list") lastSearch.product_click_count += 1;
      else lastSearch.group_product_click_count += 1;
    }
  },
  USER_CLICKED_SORT(state) {
    const lastSearch = getLastSearch(state);
    if (lastSearch) {
      lastSearch.sort_click_count += 1;
    }
  },
  UPDATE_INPUT_BOX(state, payload) {
    Object.entries(payload).forEach(([key, value]) => {
      state.input_box[key] = !!value;
    });
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
