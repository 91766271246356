<template>
  <Transition name="fade">
    <div
      v-if="compareModalOpen"
      :class="
        $twMerge(
          'fixed inset-0 md:inset-0 z-50 flex size-full items-end overflow-y-auto',
          ' bg-black bg-opacity-70 md:items-center md:justify-center',
        )
      "
    >
      <div
        :class="
          $twMerge(
            'flex h-4/5 w-full flex-col overflow-y-auto rounded-t-lg bg-white',
            'shadow-lg md:mx-auto md:max-w-4xl md:rounded',
          )
        "
      >
        <div class="flex min-h-80 flex-col">
          <div class="flex h-10 items-center justify-center">
            <Handle />
          </div>
          <ModalHeader
            :title="$t('magic_search_two_point.modal_header.compare_results')"
            class="sticky top-0 z-50 bg-white p-4"
            @close="onClose"
          />
          <SmartCompareResult class="mx-4" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {
  allowBackgroundScroll,
  blockBackgroundScroll,
} from "@/utils/scrollUtils";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModalHeader from "./ModalHeader.vue";
import SmartCompareResult from "./SmartCompareResult.vue";
import Handle from "@/components/ui/icons/Handle.vue";

export default {
  components: { ModalHeader, SmartCompareResult, Handle },
  computed: {
    ...mapGetters("search", ["searchId"]),
    ...mapGetters("hybridActions", ["selectedAdIds", "compareModalOpen"]),
  },
  watch: {
    compareModalOpen(newValue) {
      newValue ? blockBackgroundScroll() : allowBackgroundScroll();

      if (newValue) this.compare();
    },
  },
  methods: {
    ...mapMutations("hybridActions", ["SET_COMPARE_MODAL_OPEN"]),
    ...mapActions("hybridActions", ["compare"]),
    onClose() {
      this.SET_COMPARE_MODAL_OPEN(false);
    },
  },
};
</script>
