<template>
  <!-- Outer div to keep transitions stable with parent -->
  <div>
    <div
      v-if="product"
      class="relative z-0 flex overflow-hidden bg-white"
      :class="[
        {
          'rounded-l-lg': isCompactVariant,
          'h-full w-40 flex-col rounded-md': isMainVariant,
        },
        className,
      ]"
    >
      <BaseImage
        :src="image"
        :alt="product.title"
        :width="IMAGE_SIZE"
        :height="IMAGE_SIZE"
        :class="[
          'aspect-square bg-center object-cover object-top',
          {
            'size-36': isCompactVariant,
            'size-40': isMainVariant,
          },
        ]"
      />

      <!-- Product information -->
      <div class="flex grow flex-col gap-1 px-3 py-2">
        <h3
          class="line-clamp-3 min-h-4 text-sm leading-4"
          :class="{ 'pr-8': isCompactVariant }"
        >
          {{ product.title }}
        </h3>
        <div
          v-if="tagsLoading"
          class="my-2 h-4 w-full max-w-48 animate-pulse rounded-md bg-teal-700/20"
        ></div>
        <Transition name="fade" duration="300">
          <div
            v-if="contextualTag"
            class="mt-2 flex items-center overflow-hidden transition-all duration-300"
          >
            <img
              src="@/assets/icons/right.svg"
              alt="compare"
              class="mb-1 mr-1"
            />
            <span
              class="line-clamp-1 overflow-hidden text-ellipsis text-sm text-magic-700"
            >
              {{ contextualTag }}
            </span>
          </div>
        </Transition>
        <div class="mt-auto text-lg font-medium leading-4">
          {{ product.price }} zł
        </div>
        <div class="flex items-end justify-between">
          <div
            :class="[
              'line-clamp-1 text-xs font-light',
              {
                'w-20': isMainVariant,
              },
            ]"
          >
            {{ location }}
          </div>
          <RouterLink
            v-slot="{ href, navigate }"
            :to="{ path: '/search', query: { product_id: product.id } }"
            custom
          >
            <span
              v-track:click="$EVENTS?.HYBRID?.MAGIC_FIND_SIMILAR"
              :href="href"
              :class="
                $twMerge(
                  'w-1/2 shrink-0 cursor-pointer text-right',
                  'text-xs font-normal text-magic-700',
                  'underline decoration-1 underline-offset-2',
                  'z-10',
                )
              "
              @click.stop="navigate"
            >
              {{ $t("product.explore_similar") }}
            </span>
          </RouterLink>
        </div>
      </div>

      <!-- Checkbox for Selection -->
      <BaseCheckbox
        v-if="canBeSelected"
        :id="product.id"
        :checked="isSelected"
        :disabled="selectedAdIds?.length >= 3 && !isSelected"
        class="absolute right-2 top-2 z-20"
        @change="onCheckboxChange"
      />

      <ProductCardCondition
        v-if="product.condition"
        :condition="product.condition"
        class="absolute left-2 top-2"
      />

      <!-- Clickable overlay -->
      <RouterLink
        :to="`/buy/${product.id}?from=magic_hybrid&ad_tag=${contextualTag ? 1 : 0}&ad_source=${source}`"
        class="absolute inset-0 cursor-pointer"
      />
    </div>
    <Transition name="slide-left" duration="300">
      <MagicHelperBubble
        v-if="selectedAdIds?.length === 1 && isSelected && isCompactVariant"
        class="transition-all duration-300 ease-in-out"
      >
        <h1>{{ $t("magic_search_two_point.magic_helper.compare_info") }}</h1>
      </MagicHelperBubble>
    </Transition>
  </div>
</template>

<script>
import BaseImage from "@/components/ui/Image.vue";
import { RouterLink } from "vue-router";
import { mapGetters, mapMutations } from "vuex";
import ProductCardCondition from "./ProductCardCondition.vue";
import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";
import MagicHelperBubble from "../helper/MagicHelperBubble.vue";

const IMAGE_SIZE = 200;

export default {
  name: "ProductCard",
  components: {
    BaseImage,
    ProductCardCondition,
    RouterLink,
    BaseCheckbox,
    MagicHelperBubble,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: "main",
    },
    className: {
      type: String,
      default: "",
    },
    contextualTag: {
      type: String,
      default: undefined,
    },
    tagsLoading: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      IMAGE_SIZE,
    };
  },
  computed: {
    ...mapGetters({
      selectedAdIds: "hybridActions/selectedAdIds",
    }),
    isCompactVariant() {
      return this.variant === "compact";
    },
    isMainVariant() {
      return this.variant !== "compact";
    },
    location() {
      if (!this.product?.location) return null;
      const { city, district, region } = this.product.location;
      const parts = [city, district, region]
        .map((p) => p?.name)
        .filter((p) => p);
      return parts.join(", ");
    },
    image() {
      const photo = this.product.photos?.[0] ?? this.product.images?.[0];
      const link = photo?.link ?? photo?.url;
      if (!link) return null;
      return link
        .replace(/\{width\}/, IMAGE_SIZE)
        .replace(/\{height\}/, IMAGE_SIZE);
    },
    isSelected() {
      return this.selectedAdIds.includes(this.product.id);
    },
    canBeSelected() {
      const notAllowedSources = ["query_based_group", "instant_filter"];
      return notAllowedSources.includes(this.source) ? false : true;
    },
  },
  methods: {
    ...mapMutations("hybridActions", [
      "ADD_SELECTED_AD_ID",
      "REMOVE_SELECTED_AD_ID",
    ]),
    onCheckboxChange: function (checked) {
      const { id } = this.product;
      if (checked && this.selectedAdIds.length >= 3) return;

      checked ? this.ADD_SELECTED_AD_ID(id) : this.REMOVE_SELECTED_AD_ID(id);
    },
  },
};
</script>
