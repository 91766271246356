<template>
  <!-- Floating buttons and Selection Counter -->
  <div
    class="fixed bottom-4 right-1/2 z-40 mx-auto flex translate-x-1/2 flex-col items-center gap-2 md:right-32"
  >
    <!-- Button Container -->
    <div class="flex gap-3">
      <SelectedAdCounter class="md:hidden" />
      <RefineButton class="md:hidden" />
      <CompareButton class="md:hidden" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CompareButton from "./CompareButton.vue";
import RefineButton from "./RefineButton.vue";
import SelectedAdCounter from "./SelectedAdCounter.vue";

export default {
  name: "FloatingActions",
  components: {
    SelectedAdCounter,
    CompareButton,
    RefineButton,
  },
  computed: {
    ...mapGetters("hybridActions", ["selectedAdIds"]),
    ...mapGetters("search", ["refineButtonEnabled"]),
  },
  methods: {
    ...mapMutations("hybridActions", ["CLEAR_SELECTED_AD_IDS"]),
  },
};
</script>
