import { i18n } from "@/locale/i18n";

export function tipFactory({
  label,
  scope,
  position,
  maxVersions = 0,
  highlight,
  needsGroup,
  indicateAction,
}) {
  const addition = maxVersions
    ? `.${Math.floor(Math.random() * maxVersions)}`
    : "";
  const fullLabel = `search.onboarding.${label}${addition}`;
  const text = i18n.global.t(fullLabel);
  return {
    text,
    label: fullLabel,
    scope,
    position,
    needsGroup: !!needsGroup,
    highlight,
    indicateAction,
  };
}

export const TIP_SCOPE = {
  MAIN: "main",
  PRODUCTS: "products",
  GROUPS: "groups",
};
