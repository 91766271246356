<template>
  <MagicHelperOnboarding
    v-if="tip"
    :tip="tip"
    :class="{ 'pt-0': tip.needsGroup }"
  >
    <div v-if="tip.indicateAction" class="mt-1 w-full">
      <RefineButton
        v-if="tip.indicateAction === 'refine'"
        class="block scale-90 md:hidden"
      />
      <CompareButton
        v-else-if="tip.indicateAction === 'compare'"
        class="block scale-90 md:hidden"
      />
      <SwitchToChatButton
        v-else-if="tip.indicateAction === 'chat'"
        class="scale-90"
      />
    </div>
  </MagicHelperOnboarding>
</template>
<script>
import { TIP_SCOPE } from "@/store/utils/hybrid-onboarding";
import { mapGetters } from "vuex";
import CompareButton from "../actions/CompareButton.vue";
import RefineButton from "../actions/RefineButton.vue";
import MagicHelperOnboarding from "../helper/MagicHelperOnboarding.vue";
import SwitchToChatButton from "@/components/navbar/SwitchToChatButton.vue";

export default {
  name: "SearchResultItemTip",
  components: {
    MagicHelperOnboarding,
    RefineButton,
    CompareButton,
    SwitchToChatButton,
  },
  props: {
    attaches: {
      type: String,
      default: "pre",
    },
    position: {
      type: Number,
      required: true,
    },
    positionHasGroup: {
      type: Boolean,
      default: false,
    },
    containerScope: {
      type: String,
      default: TIP_SCOPE.MAIN,
    },
  },
  computed: {
    ...mapGetters("hybridOnboarding", ["resultListTips"]),
    tip: function () {
      return (this.resultListTips ?? []).find((tip) => {
        const samePosition = tip.position === this.position;
        const groupRequirement = tip.needsGroup ? this.positionHasGroup : true;
        return samePosition && groupRequirement;
      });
    },
  },
};
</script>
