<template>
  <svg
    width="40"
    height="4"
    viewBox="0 0 40 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="4" rx="2" fill="#D8DFE0" />
  </svg>
</template>
