let scrollPosition = 0;

export function blockBackgroundScroll() {
  scrollPosition = window.scrollY;

  document.body.style.position = "fixed";
  document.body.style.top = `-${scrollPosition}px`;
  document.body.style.width = "100%";
  document.body.style.overflow = "hidden";
}

export function allowBackgroundScroll() {
  document.body.style.position = "";
  document.body.style.top = "";
  document.body.style.width = "";
  document.body.style.overflow = "";

  window.scrollTo(0, scrollPosition);
}
