<template>
  <div
    :class="[
      'mb-16 grid w-full grid-cols-1 md:mb-0',
      isBroadRequest ? 'mt-0 gap-0' : 'mt-4 gap-2',
    ]"
  >
    <ProductSkeleton v-if="showProductsLoading" />
    <template v-else>
      <ProductsHeader v-if="!productsLoading && (products?.length ?? 0) > 0" />
      <TransitionGroup name="scale-down" duration="100">
        <template v-for="(item, index) in list" :key="item.id">
          <ProductGroupSkeleton v-if="item.isGroup && item.isLoader" />
          <ProductGroup
            v-else-if="item.isGroup"
            :title="item.title"
            :products="item.items"
            :source="
              item.isQueryGroup ? 'query_based_group' : 'product_based_group'
            "
          >
            <SearchResultItemTip
              :position="index"
              :position-has-group="item.isGroup"
              container-scope="products"
            />
          </ProductGroup>
          <ProductCard
            v-else
            variant="compact"
            :product="item"
            :contextual-tag="productTags?.[item.id]"
            :tags-loading="productTagsLoading"
            source="list"
            class-name="mx-4 transition-all duration-100"
            :style="{ transitionDelay: `${index * 50}ms` }"
          />
        </template>
      </TransitionGroup>
    </template>
    <NextSearchSuggestions />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductGroupSkeleton from "../ui/ProductGroupSkeleton.vue";
import ProductSkeleton from "../ui/ProductSkeleton.vue";
import ProductCard from "./products/ProductCard.vue";
import ProductGroup from "./products/ProductGroup.vue";
import ProductsHeader from "./products/ProductsHeader.vue";
import NextSearchSuggestions from "./results/NextSearchSuggestions.vue";
import SearchResultItemTip from "./results/SearchResultItemTip.vue";

export default {
  name: "SearchResults",
  components: {
    ProductGroup,
    ProductCard,
    ProductsHeader,
    ProductGroupSkeleton,
    ProductSkeleton,
    NextSearchSuggestions,
    SearchResultItemTip,
  },
  computed: {
    ...mapGetters("search", [
      "list",
      "productTags",
      "productTagsLoading",
      "products",
      "productsLoading",
      "isBroadRequest",
      "status",
    ]),
    showProductsLoading() {
      return this.isBroadRequest
        ? (this.list?.length ?? 0) === 0
        : this.productsLoading;
    },
  },
};
</script>
