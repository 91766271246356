<template>
  <div
    ref="conversationDisplay"
    class="relative flex flex-col border-gray-200 bg-white px-4 pt-4 md:overflow-y-auto"
    @scroll="onScroll"
    @mousedown="interactionStarted"
    @mouseup="interactionEnded"
    @touchstart="interactionStarted"
    @touchend="interactionEnded"
    @pointerdown="interactionStarted"
    @pointerup="interactionEnded"
    @wheel="autoScroll = false"
  >
    <ConversationHeader v-if="shouldShowInitialMessage" />

    <Transition name="fade">
      <ExamplesGallery
        v-if="shouldShowInitialMessage"
        class="h-full transition-all"
        :is-focused="isFocused"
        @example-selected="$emit('example-selected', $event)"
      />
    </Transition>

    <div
      v-for="(group, i) in groups"
      :key="group[0].timestamp"
      class="flex flex-col"
      :class="i === groups.length - 1 ? 'full-group' : ''"
    >
      <Message
        v-for="message in group"
        :key="message.timestamp"
        :timestamp="message.timestamp.toString()"
        :title="message.title"
        :products="message.products ?? []"
        :text="message.text"
        :is-user="message.isUser"
        :files-urls="message.filesUrls"
        :message-type="message.messageType"
        :message-status="message.status"
        :search-results="message.searchResults"
        :feedback="message.feedback"
        :is-contextual-entry-message="message.isContextualEntryMessage"
        :class="{
          'mb-8': !message.isUser && !message.isContextualEntryMessage,
          'mb-4': message.isUser || message.isContextualEntryMessage,
        }"
      />

      <template v-if="i == groups.length - 1">
        <div
          v-if="status === 'working'"
          class="relative my-4 flex flex-col items-start gap-2 sm:gap-3"
        >
          <p
            v-if="shouldShowContextualEntryLoader"
            class="mb-2 rounded-lg bg-grey-300 px-2.5 py-3 font-bold text-grey-700"
          >
            {{ getContextualEntryLoadingText() }}
          </p>

          <div class="flex">
            <img
              alt="bi-robot"
              class="mr-2 size-6 border-transparent"
              src="@/assets/icons/olx_logo.svg"
            />
            <Loader />
          </div>
        </div>

        <Transition name="fade">
          <FollowUpSuggestions
            v-if="followUpResponses.length > 0"
            class="mt-auto"
          />
        </Transition>
      </template>
    </div>

    <ScrollToBottomButton
      :visible="!!(showScrollButton && messages.length)"
      @click="onClickScrollToBottom"
    />
  </div>
</template>

<script>
import Loader from "@/components/ui/LoaderColored.vue";
import {
  APP_DOWNLOAD_MESSAGE_LIMIT,
  DID_SEE_APP_DOWNLOAD,
} from "@/constants/floatingItemConstants";
import { filterEntryQueryParams } from "@/utils/entry-point";
import { getItem, setItem } from "@/utils/storage";
import debounce from "lodash.debounce";
import resolveConfig from "tailwindcss/resolveConfig";
import { mapActions, mapMutations, mapState } from "vuex";
import tailwindConfig from "../../../tailwind.config";
import ExamplesGallery from "./conversation/ExamplesGallery.vue";
import FollowUpSuggestions from "./conversation/FollowUpSuggestions.vue";
import Message from "./conversation/Message.vue";
import ConversationHeader from "./ConversationHeader.vue";
import ScrollToBottomButton from "./ScrollToBottomButton.vue";
import { checkAndTriggerOnboarding } from "@/store/utils/onboarding";
import { ONBOARDING_TYPE } from "@/constants/onboardingConstants";
import { MOBILE_CTA_VISIBLE } from "@/constants/storeLinks";

const DEBOUNCE_TIME = 50;
const APP_TRIGGER_ENABLED = false;

export default {
  name: "ConversationDisplay",
  components: {
    Message,
    FollowUpSuggestions,
    ExamplesGallery,
    ConversationHeader,
    ScrollToBottomButton,
    Loader,
  },
  props: {
    isFocused: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["example-selected"],
  data() {
    const entryParams = filterEntryQueryParams(this.$route.query);

    return {
      entryParams,
      showScrollButton: false,
      autoScroll: false,
      prevScrollValue: false,
      userInteracting: false,
      scrollContainer: null,
      scrollListener: null,
      scrollHandlerAdded: false, // Track if the event listener has been added
    };
  },
  computed: {
    ...mapState("messages", [
      "messages",
      "followUpResponses",
      "status",
      "sessionId",
      "new_session",
      "chat_offsetY",
    ]),
    ...mapState({
      screenWidth: (state) => state.windowSize.windowWidth,
    }),
    followUpResponsesLength() {
      return this.followUpResponses?.length ?? 0;
    },
    messagesLength() {
      return this.messages.length;
    },
    isLastMessageUser() {
      return this.messages[this.messages.length - 1]?.isUser;
    },
    groups() {
      const groups = [];
      let group = [];
      const messages = Array.from(this.messages);
      for (let i = 0; i < messages.length; i++) {
        const message = messages[i];
        if (message.isUser) {
          groups.push(group);
          group = [message];
        } else {
          group.push(message);
        }
      }
      groups.push(group);
      return groups.filter((g) => g.length > 0);
    },
    hasEntryParams() {
      return Object.keys(this.entryParams).length > 0;
    },
    shouldShowInitialMessage() {
      return this.messages.length > 0 ? false : !this.hasEntryParams;
    },
    shouldShowContextualEntryLoader() {
      const filtered = this.messages.filter((m) => !m.isContextualEntryMessage);
      return filtered.length > 0 ? false : this.hasEntryParams;
    },
  },
  watch: {
    messagesLength(newValue, oldValue) {
      const newMsg = newValue > oldValue;
      if (newMsg && this.autoScroll) this.scrollToBottom();
      if (newMsg && this.isLastMessageUser)
        this.$nextTick(() => this.scrollToBottom());

      const hasShownModal = getItem(DID_SEE_APP_DOWNLOAD);
      if (
        MOBILE_CTA_VISIBLE &&
        newValue > APP_DOWNLOAD_MESSAGE_LIMIT &&
        !hasShownModal
      ) {
        if (!APP_TRIGGER_ENABLED) return;
        this.SET_DOWNLOAD_APP_VISIBLE(true);
        setItem(DID_SEE_APP_DOWNLOAD, true);
      }

      this._triggerOnboarding();
    },
    followUpResponsesLength(newValue) {
      if (newValue > 0 && this.autoScroll)
        this.$nextTick(() => this.scrollToBottom());
    },
    isFocused(newVal) {
      if (newVal && this.autoScroll) {
        this.prevScrollValue = this.autoScroll;
        this.autoScroll = !newVal;
      }
      if (!newVal) this.autoScroll = this.prevScrollValue;
    },
  },
  updated() {
    this.updatedDebounced();
  },
  mounted() {
    this._scrollPositionSideEffects();
    this._setScrollHandler();
    this.scrollToOffset();

    const resizeHandler = debounce(this._scrollPositionSideEffects, 100).bind(
      this,
    );
    window.addEventListener("resize", resizeHandler);

    this.resizeHandler = resizeHandler;
  },
  beforeUnmount() {
    if (this.scrollContainer && this.scrollHandler) {
      this.scrollContainer.removeEventListener("scroll", this.scrollHandler);
    }
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    ...mapActions("messages", ["updateChatOffsetY"]),
    ...mapMutations("floatingItems", ["SET_DOWNLOAD_APP_VISIBLE"]),
    interactionStarted() {
      this.userInteracting = true;
    },
    interactionEnded() {
      this.userInteracting = false;
    },
    onClickScrollToBottom() {
      this.autoScroll = true;
      this.scrollToBottom();
    },
    scrollToBottom() {
      const elm = this.scrollContainer;
      elm.scrollTo({ top: elm.scrollHeight, behavior: "smooth" });
    },
    scrollToOffset() {
      const elm = this.scrollContainer;
      if (elm) elm.scrollTop = this.chat_offsetY;
    },
    onScroll() {
      if (this.userInteracting) this.autoScroll = false;
      this.setValuesAfterScroll();
      this.debouncedUpdateOffset();
    },
    debouncedUpdateOffset: debounce(function () {
      if (this.scrollContainer) {
        this.offsetY = this.scrollContainer.scrollTop;
        this.updateChatOffsetY(this.offsetY);
      }
    }, DEBOUNCE_TIME),
    updatedDebounced: debounce(function () {
      this.setValuesAfterScroll();
      if (this.autoScroll) this.scrollToBottom();
    }, DEBOUNCE_TIME),
    setValuesAfterScroll: debounce(function () {
      const elm = this.scrollContainer;
      const bottomOffset = 30;
      if (!elm) return;
      this.showScrollButton =
        elm.scrollTop + elm.clientHeight < elm.scrollHeight - bottomOffset;
    }, DEBOUNCE_TIME),
    getContextualEntryLoadingText() {
      const { query, category } = this.entryParams;

      let key = "contextual_entry_searching";
      if (query && category) key += "_with_query_and_category";
      else if (query) key += "_with_query";
      else if (category) key += "_with_category";

      return this.$t(`conversation.${key}`, { query, category });
    },
    _setScrollHandler() {
      if (this.scrollContainer && !this.scrollHandlerAdded) {
        this.scrollHandler = this.onScroll.bind(this);
        this.scrollListener?.addEventListener("scroll", this.scrollHandler);
        this.scrollHandlerAdded = true;
      }

      this.onScroll();
    },
    _scrollPositionSideEffects() {
      const appContainer = document.querySelector("html");

      const config = resolveConfig(tailwindConfig);
      const smallerMD = this.screenWidth <= parseInt(config.theme.screens.md);
      this.scrollContainer = smallerMD
        ? appContainer
        : this.$refs.conversationDisplay;
      this.scrollListener = smallerMD ? window : this.scrollContainer;

      this._resetScrollHandler();
      this._setScrollHandler();
    },
    _resetScrollHandler() {
      if (!this.scrollHandlerAdded) return;
      this.scrollHandlerAdded = false;
      this.scrollListener?.removeEventListener("scroll", this.scrollHandler);
    },
    _triggerOnboarding() {
      for (const type of Object.values(ONBOARDING_TYPE)) {
        const triggered = checkAndTriggerOnboarding(type);
        if (triggered) break;
      }
    },
  },
};
</script>

<style scoped>
/* mobile: Full screen - navbar - chat - spacing */
/* >mobile: 100% - spacing on greater mobile */
.full-group {
  min-height: calc(
    100dvh - var(--navbar-height) - var(--chat-input-height-mobile) -
      theme("spacing.8")
  );
  @screen md {
    min-height: 100%;
  }
}
</style>
