<template>
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9 4.211C8.654 4.211 5.2 7.6837 5.2 11.9528V21.2214L8.0083 19.8101L8.5 19.6935H15.1C19.346 
      19.6935 22.8 16.2208 22.8 11.9528C22.8 7.6848 19.346 4.211 15.1 4.211H12.9ZM4.5917 24L3 
      23.01V11.9539C3 6.4638 7.4407 2 12.9 2H15.1C20.5582 2 25 6.4649 25 11.9528C25 17.4407 20.5582 
      21.9045 15.1 21.9045H8.7596L4.5917 24Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 12.5422C10.2023 12.5422 11.2215 12.9713 12.0934 13.8419C12.9661 14.7132 13.4232 15.7575 
      13.4799 17L14.4991 17C14.5558 15.7579 15.0125 14.7146 15.8941 13.8439L15.8961 13.8419C16.7672 
      12.9721 17.7965 12.5422 19 12.5422L19 11.4728C18.2042 11.4728 17.4812 11.2766 16.8019 
      10.8832C16.1175 10.4758 15.5723 9.93439 15.1678 9.25686L15.1668 9.2551C14.7536 8.56901 
      14.5371 7.8258 14.4991 7.0037L13.4801 7C13.4135 8.25999 12.9636 9.30428 12.0934 10.173C11.2215 
      11.0437 10.2023 11.4728 9 11.4728L9 12.5422Z"
    />
  </svg>
</template>
