<template>
  <div
    :class="[
      'rounded-lg px-4 py-5 shadow-md md:px-12 md:py-10',
      'relative z-0 overflow-hidden bg-grey-300',
    ]"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "HomeCard",
};
</script>
