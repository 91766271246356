<template>
  <EntryView
    :loading="loading"
    :error="error"
    @complete-entry="completeEntry"
  />
</template>

<script>
import { completeEntryResponse } from "@/auth/magic_auth";
import { MAGIC_MOBILE_ENTRY } from "@/constants/eventsConstants";
import { completeEntry } from "@/services/AuthService";
import TrackingService from "@/services/TrackingService";
import EntryView from "./EntryView.vue";
import { toEntryPage } from "./utils";

export default {
  name: "MobileEntry",
  components: {
    EntryView,
  },
  data() {
    return {
      loading: true,
      error: undefined,
    };
  },
  mounted() {
    this.completeEntry();
  },
  methods: {
    async completeEntry() {
      try {
        this.loading = true;
        this.error = undefined;
        const { key, st, ...rest } = this.$route.query;
        const resp = await completeEntry({ key, st });

        const { client_s, client_sl } = await completeEntryResponse(resp);
        TrackingService.trackPage({
          ...MAGIC_MOBILE_ENTRY,
          client_s,
          client_sl,
        });

        this.$nextTick(() => toEntryPage(rest, "/search"));
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
