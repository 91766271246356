<template>
  <div
    :class="[
      'container my-1 flex flex-col items-center justify-between gap-1',
      'rounded-lg bg-blue-200 px-4 py-2 text-sm shadow-sm sm:flex-row sm:gap-4',
    ]"
  >
    <p class="m-0 w-full grow leading-4 text-grey-700">
      {{ $t("disclaimer") }}
    </p>
    <div class="flex w-full justify-end sm:w-auto">
      <span
        class="shrink-0 cursor-pointer !py-3 font-medium !text-grey-700 underline decoration-2 underline-offset-4"
        variant="secondary"
        translate="no"
        @click="shareFeedback"
      >
        {{ $t("feedback") }}
      </span>
    </div>
  </div>
</template>

<script>
import TrackingService from "./../services/TrackingService";

export default {
  name: "NotificationBar",
  methods: {
    shareFeedback() {
      const surveyId = "739";
      TrackingService.showSurvey(surveyId);
    },
  },
};
</script>
