<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.97603 5.75L7.93436 2.875H15.0653L16.0241 5.75H20.1248L21.0832 6.70833V19.1667L20.1248 
      20.125H2.87484L1.9165 19.1667V6.70833L2.87484 5.75H6.97603ZM3.83317 
      7.66667V18.2083H19.1665V7.66667H14.6427L13.6844 4.79167H9.3158L8.35746 7.66667H3.83317Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 12.8858C8.47479 12.8858 9.30105 13.2337 10.008 13.9396C10.7155 14.646 11.0861 15.4926 11.1321 
      16.5H11.9584C12.0044 15.4929 12.3746 14.6471 13.0894 13.9412L13.091 13.9396C13.7973 13.2344 14.6318 
      12.8858 15.6075 12.8858V12.0188C14.9623 12.0188 14.3762 11.8597 13.8254 11.5408C13.2705 11.2105 12.8285 
      10.7715 12.5006 10.2222L12.4997 10.2208C12.1648 9.66456 11.9892 9.062 11.9584 8.39548L11.1322 
      8.39248C11.0783 9.41402 10.7135 10.2607 10.008 10.965C9.30105 11.6709 8.47479 12.0188 7.5 12.0188V12.8858Z"
    />
  </svg>
</template>
<script>
export default {
  name: "CameraIcon",
};
</script>
