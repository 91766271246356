<template>
  <div>
    <div
      :class="
        $twMerge(
          'flex w-full flex-col items-center justify-between',
          'px-4 py-2 shadow-sm sm:px-0 md:pb-0',
        )
      "
    >
      <Dropdown
        class="inline"
        popper-class="custom-dropdown w-80 md:w-96"
        :shown="onboardingTipVisible.input_box"
        :auto-hide="true"
        :distance="6"
        placement="top"
      >
        <!-- Popper content -->
        <template #popper="{ hide }">
          <DropdownPopper
            :hide="hide"
            :tooltip-text="'tour_tooltip_text.input_box'"
          />
        </template>
      </Dropdown>
      <div :class="$twMerge('relative w-full')">
        <div
          :class="
            $twMerge(
              'box-border flex min-h-12 items-end gap-2',
              'rounded-lg border bg-white p-2 shadow-md',
              'sm:px-4 sm:py-3',
            )
          "
        >
          <div
            ref="contentEditable"
            :key="inputPlaceholder"
            contenteditable="true"
            :data-placeholder="
              isRecording ? $t('placeholders.audio') : inputPlaceholder
            "
            :class="
              $twMerge(
                'flex size-full max-h-[calc(2em*3)] resize-none items-center',
                'overflow-hidden overflow-y-auto p-2 text-base font-normal outline-0',
                'empty:before:text-gray-400 empty:before:content-[attr(data-placeholder)]',
                { truncate: trimmedMessage === '' },
              )
            "
            @input="onInput"
            @keydown="onKeyDown"
            @paste="onPaste"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @click.stop.capture="
              () => {
                if (isRecording) {
                  stopRecording();
                  isRecording = false;
                }
              }
            "
          ></div>
          <div :class="$twMerge('mb-1 flex items-center gap-3')">
            <div
              :class="
                $twMerge('flex h-full items-center justify-start gap-3', {
                  flex: trimmedMessage !== '' || filesUrls.length !== 0,
                })
              "
            >
              <button
                v-if="task_status === 'completed'"
                v-track:click="MAGIC_ATTACHMENT_CLICK"
                :class="
                  $twMerge(
                    'relative flex size-8 items-center justify-center',
                    'rounded-full text-white transition duration-200',
                    'hover:bg-gray-300 focus:outline-none focus:ring-gray-500',
                  )
                "
                :disabled="files.length >= 10"
                @click.stop.capture="handleUploadButtonClick"
              >
                <Dropdown
                  class="inline"
                  popper-class="custom-dropdown w-80 md:w-96"
                  :shown="onboardingTipVisible.visual_search"
                  :auto-hide="true"
                  :distance="6"
                  placement="top"
                  append-to="parent"
                >
                  <img
                    src="@/assets/icons/paper_clip.svg"
                    :class="
                      $twMerge(
                        'dropdown-trigger hidden size-6',
                        'transition duration-150 ease-in-out md:block',
                      )
                    "
                    alt="Upload Icon"
                  />
                  <img
                    src="@/assets/icons/gallery.svg"
                    :class="
                      $twMerge(
                        'dropdown-trigger size-6',
                        'transition duration-150 ease-in-out md:hidden',
                      )
                    "
                    alt="Gallery Icon"
                  />
                  <!-- Popper content -->
                  <template #popper="{ hide }">
                    <DropdownPopper
                      :hide="hide"
                      :tooltip-text="'tour_tooltip_text.visual_search'"
                    />
                  </template>
                </Dropdown>
              </button>
              <input
                ref="fileInput"
                type="file"
                accept=".jpeg, .jpg, .heic, .png, .webp"
                class="hidden"
                style="display: hidden"
                @change="handleFileUpload"
              />
              <button
                v-if="task_status === 'completed'"
                v-track:click="MAGIC_CAMERA_CLICK"
                :class="
                  $twMerge(
                    'flex size-8 items-center justify-center',
                    'rounded-full text-white transition duration-200',
                    'hover:bg-gray-300 focus:outline-none focus:ring-gray-500 md:flex',
                  )
                "
                @click="openCameraModal"
              >
                <img
                  src="@/assets/icons/camera.svg"
                  class="size-6"
                  alt="Camera Icon"
                />
              </button>
              <button
                v-if="
                  !isRecording &&
                  task_status === 'completed' &&
                  trimmedMessage === ''
                "
                v-track:click="MAGIC_MICROPHONE_CLICK"
                :class="
                  $twMerge(
                    'flex size-8 items-center justify-center',
                    'rounded-full text-white transition duration-200',
                    'hover:bg-gray-300 focus:outline-none focus:ring-2',
                    'focus:ring-gray-500 focus:ring-offset-2',
                  )
                "
                @click="toggleRecording"
              >
                <img
                  src="@/assets/icons/microphone.svg"
                  class="size-6"
                  alt="Microphone Icon"
                />
              </button>
              <button
                v-if="
                  isRecording &&
                  task_status === 'completed' &&
                  trimmedMessage === ''
                "
                :class="
                  $twMerge(
                    'flex size-8 items-center justify-center',
                    'rounded-full border-2 border-grey-700',
                    'bg-grey-700 text-grey-300 focus:outline-none',
                  )
                "
                @click="toggleRecording"
              >
                <Microphone />
              </button>
            </div>
            <div
              v-if="task_status !== 'completed'"
              v-track:click="MAGIC_STOP_GENERATION"
              :class="
                $twMerge(
                  'flex size-8 cursor-pointer items-center justify-center',
                  'rounded-full bg-grey-700 p-2',
                )
              "
              @click="stopGeneration"
            >
              <div :class="$twMerge('size-2 bg-white')"></div>
            </div>
            <div
              v-else
              :class="
                $twMerge(
                  'flex size-8 cursor-pointer items-center justify-center',
                  'rounded-full bg-grey-700 p-2',
                  {
                    'cursor-not-allowed opacity-30':
                      (trimmedMessage === '' && filesUrls.length === 0) ||
                      task_status !== 'completed',
                    'hover:bg-grey-600':
                      (trimmedMessage !== '' || filesUrls.length !== 0) &&
                      task_status === 'completed',
                  },
                )
              "
              @click="sendUserMessage"
            >
              <span>
                <img
                  src="@/assets/icons/send.svg"
                  alt="send-icon"
                  :class="$twMerge('size-4 max-w-none')"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AttachmentModal
      :is-open="isModalOpen"
      :image-src="selectedImageSrc"
      :files-urls="filesUrls"
      :message-text="message"
      :is-upload-disabled="isUploadDisabled"
      @close="closeModal"
      @back="goBack"
      @update:files-urls="updateFilesUrls"
      @upload-click="sendMessage($event)"
    />

    <!-- Modal for Camera -->
    <CameraModal
      ref="cameraModal"
      :files="files"
      :file-previews="filePreviews"
      :files-urls="filesUrls"
      :message-text="message"
      @photo-captured="handlePhotoCaptured"
      @update:files-urls="updateFilesUrls"
    ></CameraModal>
    <CustomAlert
      v-if="showCustomAlert"
      ref="customAlert"
      @alert-closed="handleAlertClosed"
    />
  </div>
</template>

<script>
import AttachmentModal from "@/components/attachment/AttachmentModal.vue";
import {
  MAGIC_ATTACHMENT_CLICK,
  MAGIC_CAMERA_CLICK,
  MAGIC_MICROPHONE_CLICK,
  MAGIC_STOP_GENERATION,
} from "@/constants/eventsConstants";
import { processFile, uploadFile } from "@/services/FileUploadService";
import logger from "@/services/logger";
import WebSocketService from "@/services/WebSocketService";
import { Dropdown } from "floating-vue";
import resolveConfig from "tailwindcss/resolveConfig";
import { mapActions, mapGetters, mapState } from "vuex";
import DropdownPopper from "../navbar/DropdownPopper.vue";
import tailwindConfig from "./../../../tailwind.config";
import Microphone from "./../ui/icons/Micrrophone.vue";
import CameraModal from "./CameraModal.vue";
import CustomAlert from "./CustomAlert.vue";
import { sanitizeMessage } from "@/utils/string";

export default {
  name: "MessageInput",
  components: {
    CameraModal,
    AttachmentModal,
    Microphone,
    Dropdown,
    DropdownPopper,
    CustomAlert,
  },
  props: {
    promptText: {
      type: String,
      default: "",
    },
    promptKey: {
      type: String,
      default: "",
    },
    eventType: {
      type: String,
      default: "",
    },
  },
  emits: ["focus", "blur"],
  data() {
    return {
      message: "",
      isRecording: false,
      maxChars: 1000,
      maxFiles: 10,
      audioContext: null,
      mediaRecorder: null,
      deepgramSocket: null,
      audio: new Audio(),
      files: [],
      filePreviews: [],
      filesUrls: [],
      showCameraModal: false,
      capturedPhotos: [],
      showCaptureAnimation: false,
      isModalOpen: false,
      isUploadDisabled: false,
      selectedImageSrc: "",
      MAGIC_ATTACHMENT_CLICK,
      MAGIC_CAMERA_CLICK,
      MAGIC_MICROPHONE_CLICK,
      MAGIC_STOP_GENERATION,
      showCustomAlert: false,
    };
  },
  computed: {
    ...mapState("messages", ["messages", "task_status", "new_session"]),
    ...mapState("settings", [
      "selectedLocale",
      "isSpeechDetectionEnabled",
      "isTextToSpeechEnabled",
    ]),
    ...mapGetters("floatingItems", ["onboardingTipVisible"]),
    fullConfig() {
      return resolveConfig(tailwindConfig);
    },
    inputPlaceholder() {
      if (
        this.messages.length > 0 ||
        this.screenWidth < parseInt(this.fullConfig.theme.screens.sm)
      ) {
        return this.$t("placeholders.general");
      }
      const placeholders = [
        this.$t("placeholders.watch"),
        this.$t("placeholders.car"),
        this.$t("placeholders.phone"),
        this.$t("placeholders.laptop"),
      ];
      return placeholders[Math.floor(Math.random() * placeholders.length)];
    },
    ...mapState({
      screenWidth: (state) => state.windowSize.windowWidth,
    }),
    trimmedMessage() {
      return this.trimMessage(this.message);
    },
  },
  watch: {
    promptText: {
      handler(newPromptText) {
        const text = `${newPromptText} ${this.promptKey}`.trim();
        if (newPromptText) {
          this.$nextTick(() => {
            this.$refs.contentEditable?.focus();
            this.$nextTick(() => {
              this.setCaretPosition(text.length);
            });
          });
        }
        this.message = text;
        this.updateContentEditable();

        if (this.isRecording) {
          this.stopRecording();
        }
      },
      immediate: true,
      deep: true,
    },
    eventType: {
      handler(eventType) {
        if (eventType === "camera") {
          this.openCameraModal();
        }
        if (eventType === "mic") {
          this.toggleRecording();
        }
      },
    },
    new_session: {
      handler(new_session) {
        if (!new_session) {
          this.message = "";
          this.$refs.contentEditable.innerHTML = "";
          this.$refs.contentEditable.style.height = "auto";
          if (this.screenWidth >= parseInt(this.fullConfig.theme.screens.sm))
            this.$refs.contentEditable?.focus();
          this.isRecording && (this.isRecording = false);
        }
      },
    },
    task_status: {
      handler(task_status) {
        logger.log("status", task_status);
      },
    },
  },
  created() {
    this.message = this.promptText;
  },
  mounted() {
    if (this.isSpeechDetectionEnabled) {
      this.toggleRecording(new Event("click"));
    }
    // check if WebSocketService has a connectTTS method first
    if (WebSocketService.connectTTS) {
      // removed playing text as speech feature
    } else {
      if (WebSocketService.playNextAudio) {
        WebSocketService.setOnMessageReceived(WebSocketService.playNextAudio);
      }
    }

    WebSocketService.setCameraModalCallback(this.openCameraModal);
  },
  methods: {
    ...mapActions("messages", ["sendMessage", "stopGeneration"]),
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    openModal(imageSrc) {
      this.selectedImageSrc = imageSrc;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.selectedImageSrc = "";
    },
    goBack() {
      this.$refs.fileInput.click();
      this.closeModal();
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.isUploadDisabled = true;

      try {
        const { file: processedFile, selectedImage } = await processFile(file);

        this.selectedImageSrc = selectedImage;
        this.isModalOpen = true;

        const fileUrl = await uploadFile(processedFile);

        this.filesUrls.push(fileUrl);
      } catch (error) {
        logger.error("Error uploading file:", error);
      } finally {
        this.isUploadDisabled = false;
        event.target.value = null;
      }
    },
    updateFilesUrls() {
      this.filesUrls = [];
      this.filePreviews = [];
      this.files = [];
      this.message = "";
      this.$refs.contentEditable.innerHTML = "";
      this.$refs.contentEditable.style.height = "auto";
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.filePreviews.splice(index, 1);
      this.filesUrls.splice(index, 1);
    },
    handleUploadButtonClick(event) {
      if (this.isRecording) {
        this.stopRecording();
      }

      this.triggerFileUpload();
      event.target.blur();
    },
    getCaretPosition() {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(this.$refs.contentEditable);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      return preCaretRange.toString().length;
    },
    setCaretPosition(position) {
      const selection = window.getSelection();
      const range = document.createRange();
      const node = this.$refs.contentEditable;
      let charIndex = 0;
      const nodeStack = [node];
      let found = false;

      while (!found && nodeStack.length > 0) {
        const n = nodeStack.pop();
        if (n.nodeType === 3) {
          const nextCharIndex = charIndex + n.length;
          if (position >= charIndex && position <= nextCharIndex) {
            range.setStart(n, position - charIndex);
            range.setEnd(n, position - charIndex);
            found = true;
          }
          charIndex = nextCharIndex;
        } else {
          const childNodes = n.childNodes;
          for (let i = childNodes.length - 1; i >= 0; i--) {
            nodeStack.push(childNodes[i]);
          }
        }
      }

      if (found) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    },
    onInput(event) {
      const position = this.getCaretPosition();
      this.message = event.target.innerHTML;
      this.adjustHeight(event.target);
      this.$nextTick(() => {
        this.setCaretPosition(position);
      });
    },
    onKeyDown(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        event.target?.blur();
        const trimmedMessage = this.trimMessage(this.message);
        if (trimmedMessage !== "" && this.task_status === "completed") {
          this.sendUserMessage(event);
        }
      } else if (event.key === "Enter" && event.shiftKey) {
        event.preventDefault();

        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const br1 = document.createElement("br");
        const br2 = document.createElement("br");

        range.deleteContents();
        range.insertNode(br1);
        range.collapse(false);
        range.insertNode(br2);
        range.collapse(false);

        selection.removeAllRanges();
        selection.addRange(range);
      }
    },
    async startRecording() {
      try {
        if (!this.audioContext) {
          this.audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          this.mediaRecorder = new MediaRecorder(stream);
          this.setupDeepgram();
        }
      } catch (error) {
        logger.error("Error starting recording:", error);
      }
    },
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.mediaRecorder = null;
      }
      if (this.deepgramSocket) {
        this.deepgramSocket.close();
        this.deepgramSocket = null;
      }
      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
      }
      this.isRecording = false;
    },
    setupDeepgram() {
      this.deepgramSocket = new WebSocket(
        `wss://api.deepgram.com/v1/listen?model=nova-2-general&language=${this.selectedLocale.toLowerCase()}`,
        ["token", process.env.VUE_APP_DEEPGRAM_API_KEY],
      );
      this.deepgramSocket.onopen = () => {
        this.mediaRecorder.addEventListener("dataavailable", (event) => {
          if (event.data.size > 0 && this.deepgramSocket) {
            this.deepgramSocket.send(event.data);
          }
        });
        this.mediaRecorder.start(0);
      };

      this.deepgramSocket.onmessage = (message) => {
        const received = JSON.parse(message.data);
        const transcript = received.channel.alternatives[0].transcript;
        if (transcript && received.is_final) {
          this.message = `${this.message} ${transcript}`;

          if (this.message.trim() !== "" && this.isSpeechDetectionEnabled) {
            WebSocketService.audioQueue = [];
            WebSocketService.isPlaying = false;
            this.updateContentEditable();
            this.toggleRecording(new Event("click"));
          } else {
            this.updateContentEditable();
          }
        }
      };

      this.deepgramSocket.onclose = () => {
        if (this.message.trim() !== "" && this.isSpeechDetectionEnabled) {
          if (
            !WebSocketService.audio.ended ||
            WebSocketService.audioQueue.length > 0
          ) {
            WebSocketService.stopAudio();
          }

          this.sendMessage({ text: this.message, isUser: true });
          this.message = "";
          setTimeout(() => {
            this.updateContentEditable();
            this.toggleRecording(new Event("click"));
          }, 1000);
        }
      };

      this.deepgramSocket.onerror = (error) => {
        logger.error("Deepgram socket error:", error);
      };
    },
    updateContentEditable() {
      if (this.$refs.contentEditable) {
        this.$refs.contentEditable.innerHTML = this.message;
        this.$refs.contentEditable?.focus();
      }
    },
    async sendUserMessage(event) {
      event.preventDefault();
      const trimmedMessage = this.trimMessage(this.message);
      if (
        ((trimmedMessage !== "" &&
          this.message.length <= this.maxChars &&
          this.filesUrls.length <= this.maxFiles) ||
          (this.filesUrls.length > 0 &&
            this.filesUrls.length <= this.maxFiles)) &&
        this.task_status === "completed"
      ) {
        const message = {
          text: sanitizeMessage(this.message.replace(/<br>/g, "\n\n")),
          filesUrls: this.filesUrls,
          isUser: true,
        };
        await this.sendMessage(message);
        this.message = "";
        this.$refs.contentEditable.innerHTML = "";
        this.$refs.contentEditable.style.height = "auto";
        if (this.screenWidth >= parseInt(this.fullConfig.theme.screens.sm))
          this.$refs.contentEditable?.focus();
        this.files = [];
        this.filePreviews = [];
        this.filesUrls = [];
      }

      if (this.isRecording) {
        this.stopRecording();
      }
    },
    async toggleRecording(event) {
      event?.preventDefault();
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        this.isRecording = !this.isRecording;
        if (this.isRecording) {
          await this.startRecording();
        } else {
          this.stopRecording();
        }
      } catch {
        this.stopRecording();
        this.showAlert();
      }
    },
    async openCameraModal() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        if (this.isRecording) {
          this.stopRecording();
        }
        this.$refs.cameraModal.openCameraModal();
      } catch {
        this.stopRecording();
        this.showAlert();
      }
    },
    handleAlertClosed() {
      this.showCustomAlert = false;
    },
    showAlert() {
      if (this.$refs.customAlert) {
        this.$refs.customAlert.openAlert();
      } else {
        this.showCustomAlert = true;
      }
    },
    closeCameraModal() {
      this.$refs.cameraModal.closeCameraModal();
    },
    async handlePhotoCaptured(file) {
      await uploadFile(file).then((fileUrl) => {
        this.filesUrls = [fileUrl];
      });
      this.files = [file];
      this.filePreviews = [
        {
          src: URL.createObjectURL(file),
          type: file.type,
        },
      ];
    },
    adjustHeight(element) {
      element.style.height = "auto";
    },
    onPaste(event) {
      event.preventDefault();
      const text = event.clipboardData.getData("text/plain");
      const sanitizedText = sanitizeMessage(text);
      this.insertTextAtCursor(sanitizedText);
    },

    insertTextAtCursor(text) {
      const selection = window.getSelection();
      if (selection.rangeCount) {
        const range = selection.getRangeAt(0);
        range.deleteContents();
        const textNode = document.createTextNode(text);
        range.insertNode(textNode);
        range.setStartAfter(textNode);
        range.setEndAfter(textNode);
        selection.removeAllRanges();
        selection.addRange(range);
        this.onInput({ target: this.$refs.contentEditable });
      }
    },

    trimMessage(message) {
      message = message.replace(/&nbsp;/g, " ");
      message = message.trim();
      return message;
    },
  },
};
</script>
