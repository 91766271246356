<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.33301 0.832764C1.05687 0.832764 0.833008 1.05662 0.833008 1.33276V3.1886V3.18865C0.833065 
      3.7275 1.03391 4.24699 1.39634 4.64573L1.39637 4.64577L4.99967 8.6094V15.4994C4.99967 15.6601 
      5.07693 15.8111 5.2073 15.905C5.33768 15.999 5.50528 16.0246 5.65775 15.9738L8.98359 
      14.8655C9.24557 14.7781 9.38717 14.495 9.29986 14.233C9.21256 13.971 8.92941 13.8294 
      8.66743 13.9167L5.99967 14.8058V8.4161C5.99967 8.29171 5.95331 8.1718 5.86964 8.07976L2.13634 
      3.97313L2.13631 3.97309C1.94119 3.7584 1.83305 3.47871 1.83301 
      3.1886V3.18854V1.83276H14.1663V3.14266V3.14276C14.1662 3.45212 14.0433 
      3.74877 13.8245 3.96749L13.8245 3.96754L10.1461 7.64588C10.0524 7.73965 9.99967 
      7.86682 9.99967 7.99943V10.4994C9.99967 10.7756 10.2235 10.9994 10.4997 10.9994C10.7758 
      10.9994 10.9997 10.7756 10.9997 10.4994V8.20654L14.5315 4.6747L14.5316 4.67465C14.9379 4.26842 
      15.1662 3.71743 15.1663 3.14287V3.14276V1.33276C15.1663 1.05662 14.9425 0.832764 14.6663 
      0.832764H1.33301ZM11.5404 11.9592C12.0153 11.4836 12.2493 10.9277 12.2493 10.2719H12.8326C12.8326 
      10.9277 13.0667 11.4836 13.5416 11.9592C14.0155 12.4338 14.5851 12.6793 15.2723 12.7156L15.2703 
      13.2714C14.8219 13.2921 14.4165 13.4102 14.0423 13.6356L14.0413 13.6362C13.6718 13.8568 13.3764 
      14.1542 13.1543 14.5275C12.9397 14.898 12.8326 15.2924 12.8326 15.7265H12.2493C12.2493 15.07 
      12.0148 14.5086 11.5404 14.0334L11.5393 14.0323C11.0644 13.5515 10.4953 13.3023 9.81779 
      13.2714V12.7155C10.4955 12.6846 11.0651 12.4352 11.5404 11.9592Z"
    />
  </svg>
</template>
