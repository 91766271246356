<template>
  <div v-scroll-to-element class="relative z-0 scroll-mt-32 bg-white">
    <div
      class="container relative mx-auto overflow-x-hidden px-5 pb-8 pt-12 md:overflow-x-visible md:pt-28"
    >
      <!-- Header Section -->
      <div class="mb-28 text-left md:mb-36">
        <h1
          class="mb-4 text-4xl font-bold leading-10 text-home-300 md:mb-12 md:text-7xl md:leading-[92.4px]"
        >
          {{ $t("magic_search_two_point.home_page.header.find_it") }}
          <div>
            <span
              :class="[
                'bg-magic-gradient bg-clip-text text-4xl font-bold',
                'leading-10 text-transparent md:text-7xl md:leading-[92.4px]',
              ]"
            >
              {{ $t("magic_search_two_point.home_page.header.with_magic") }}
            </span>
          </div>
        </h1>
        <div class="text-lg font-medium text-home-300 md:text-2xl">
          <p>{{ $t("magic_search_two_point.home_page.header.smart_buddy") }}</p>
          <p>{{ $t("magic_search_two_point.home_page.header.new_way") }}</p>
        </div>
        <HomeSearchBar class="mt-4 md:mt-6" />
        <HomeColorArea
          class="-right-1/2 -top-10p rotate-45 md:-right-1/2 md:top-0"
        />
      </div>

      <!-- Video Section -->
      <video
        ref="video"
        class="my-4 max-h-[80vh] w-auto rounded-lg object-contain shadow-huge md:my-12"
        :src="videoPath"
        autoplay
        loop
        muted
        playsinline
      ></video>

      <!-- Magic search section -->
      <HomeCard class="my-4 flex flex-wrap gap-4 !pb-0 md:my-12">
        <div class="mb-8 w-full md:w-1/2">
          <h2 class="text-2xl font-semibold text-home-300 md:text-3xl">
            {{ $t("magic_search_two_point.home_page.features.it_feels") }}
          </h2>
          <h2 class="text-2xl font-semibold text-magic-700 md:text-3xl">
            {{ $t("magic_search_two_point.home_page.features.magic") }}
          </h2>
          <p class="mt-1 text-sm font-normal text-home-200 md:mt-4 md:text-lg">
            {{
              $t("magic_search_two_point.home_page.features.explore_new_way")
            }}
          </p>
        </div>
        <img
          :src="
            require(
              `@/assets/images/search_image_${selectedLocale.toLowerCase()}.png`,
            )
          "
          alt="Search Image"
          class="mx-auto w-[360px] shadow-full"
        />
        <HomeColorArea class="right-1/4 top-1/4 rotate-[-22deg]" />
      </HomeCard>

      <!-- Magic refine and app download section -->
      <div class="my-4 grid grid-cols-1 gap-8 md:my-12 md:grid-cols-2">
        <HomeCard class="flex flex-col justify-between !pb-0">
          <div class="mb-8 w-full md:w-2/3">
            <h2 class="text-2xl font-semibold text-home-300 md:text-[26px]">
              {{
                $t("magic_search_two_point.home_page.features.refine_and_find")
              }}
            </h2>
            <p
              class="mt-1 text-sm font-normal text-home-200 md:mt-4 md:text-lg"
            >
              {{
                $t("magic_search_two_point.home_page.features.refine_prompt")
              }}
            </p>
            <p class="text-sm font-normal text-home-200 md:text-lg">
              {{
                $t(
                  "magic_search_two_point.home_page.features.refine_description",
                )
              }}
            </p>
          </div>
          <img
            :src="
              require(
                `@/assets/images/refine_image_${selectedLocale.toLowerCase()}.png`,
              )
            "
            alt="Refine Image"
            class="mx-auto w-[360px] shadow-full"
          />
          <HomeColorArea class="-right-1/4 top-1/3 rotate-[22deg]" />
        </HomeCard>
        <HomeCard class="flex flex-col">
          <!-- Logos -->
          <div class="relative z-0 mx-auto flex justify-center p-6 md:p-11">
            <TitleLead class="size-32 md:size-44" />
            <div
              class="absolute right-0 top-0 flex size-2/5 items-center justify-center"
            >
              <TitleLead class="size-8 md:size-11" />
            </div>
          </div>
          <div class="w-2/3">
            <h2 class="text-2xl font-semibold text-home-300 md:text-[26px]">
              {{ $t("magic_search_two_point.home_page.features.never_miss") }}
            </h2>
            <p
              class="mt-1 text-sm font-normal text-home-200 md:mt-4 md:text-lg"
            >
              {{
                $t(
                  "magic_search_two_point.home_page.features.never_miss_desc_one",
                )
              }}
            </p>
            <p class="text-sm font-normal text-home-200 md:text-lg">
              {{
                $t(
                  "magic_search_two_point.home_page.features.never_miss_desc_two",
                )
              }}
            </p>
          </div>
          <HomeColorArea class="right-1/4 top-1/3 rotate-[-22deg]" />
        </HomeCard>
      </div>
    </div>
  </div>
  <footer class="container mx-auto mt-8 border-t border-grey-400 pb-8 pt-4">
    <div class="flex flex-col items-end space-y-2">
      <RouterLink
        to="/terms-conditions"
        target="_blank"
        class="text-sm font-normal hover:underline"
      >
        {{ $t("magic_search_two_point.policies.terms") }}
      </RouterLink>
      <RouterLink
        to="/privacy"
        target="_blank"
        class="text-sm font-normal hover:underline"
      >
        {{ $t("magic_search_two_point.policies.privacy") }}
      </RouterLink>
      <RouterLink
        to="/cookies"
        target="_blank"
        class="text-sm font-normal hover:underline"
      >
        {{ $t("magic_search_two_point.policies.cookie") }}
      </RouterLink>
    </div>
  </footer>
</template>

<script>
import videoDesktopPL from "@/assets/home/magic_desktop_pl.webm";
import videoMobilePL from "@/assets/home/magic_mobile_pl.webm";
import videoDesktopEN from "@/assets/home/magic_desktop_en.webm";
import videoMobileEN from "@/assets/home/magic_mobile_en.webm";
import { getMagicToken } from "@/auth/magic_auth";
import { MAGIC_HOMEPAGE, MAGIC_START_NOW } from "@/constants/eventsConstants";
import {
  VUE_APP_APP_STORE_LINK,
  VUE_APP_PLAY_STORE_LINK,
} from "@/constants/storeLinks";
import logger from "@/services/logger";
import resolveConfig from "tailwindcss/resolveConfig";
import { mapState } from "vuex";
import tailwindConfig from "../../tailwind.config";
import TrackingService from "../services/TrackingService";
import HomeSearchBar from "./home/HomeSearchBar.vue";
import HomeCard from "./home/HomeCard.vue";
import HomeColorArea from "./home/HomeColorArea.vue";
import TitleLead from "./ui/icons/TitleLead.vue";

export default {
  name: "HomePage",
  components: {
    HomeSearchBar,
    HomeCard,
    HomeColorArea,
    TitleLead,
  },
  data() {
    return {
      message: "",
      MAGIC_START_NOW,
      playStoreLink: VUE_APP_PLAY_STORE_LINK,
      appStoreLink: VUE_APP_APP_STORE_LINK,
      qrLink: `/img/download_qr/download_qr_${process.env.VUE_APP_STAGE ?? "production"}.png`,
    };
  },
  computed: {
    ...mapState("settings", ["selectedLocale"]),
    screenWidth() {
      return this.$store.state.windowSize.windowWidth;
    },
    fullConfig() {
      return resolveConfig(tailwindConfig);
    },
    videoPath() {
      const isDesktop =
        this.screenWidth >= parseInt(this.fullConfig.theme.screens.md);
      const deviceType = isDesktop ? "Desktop" : "Mobile";

      const videos = {
        videoDesktopEN,
        videoDesktopPL,
        videoMobileEN,
        videoMobilePL,
      };

      return videos[`video${deviceType}${this.selectedLocale}`];
    },
    isAuthenticated() {
      return this.$auth0.isAuthenticated.value;
    },
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      async handler(isAuthenticated) {
        if (!isLoginRedirect()) return;

        if (!isAuthenticated) {
          try {
            await this.$auth0.handleRedirectCallback(window.location.href);
          } catch (e) {
            // TODO keep till we know how to handle these
            if (e.message === "Invalid State") {
              logger.warn("Invalid state error");
            } else if (e.message === "Missing state") {
              logger.warn("Missing state error");
            }
            logger.error("Error handling redirect callback");
          } finally {
            this.forceUpdateAuthStatus();
          }
        } else {
          this.$nextTick(() => this.$router.push("/search"));
        }
      },
    },
  },
  mounted() {
    TrackingService.trackPage(MAGIC_HOMEPAGE);
    this.$nextTick(() => {
      try {
        this.$refs.video?.play();
      } catch (e) {
        logger.error("Error playing video", e);
      }
    });
  },
  methods: {
    getStarted() {
      if (this.isAuthenticated || getMagicToken()) this.$router.push("/search");
      else this.$auth0.loginWithRedirect();
    },
    async forceUpdateAuthStatus() {
      try {
        await this.$auth0.checkSession();
      } catch (e) {
        logger.error("Error checking session", e);
      }
    },
  },
};

function isLoginRedirect() {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.has("code") && queryParams.has("state");
}
</script>
