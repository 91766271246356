<template>
  <BaseButton
    v-track:click="$EVENTS?.HYBRID?.MAGIC_SMART_COMPARE"
    variant="primary-dark"
    :floating="true"
    :disabled="selectedAdIds.length < 2"
    @click="onShowCompare"
  >
    <CompareIcon class="size-5" :is-disabled="selectedAdIds.length < 2" />
    <span class="text-sm font-medium">{{
      $t("magic_search_two_point.action_bar_button_text.compare")
    }}</span>
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import { mapGetters, mapMutations } from "vuex";
import CompareIcon from "./CompareIcon.vue";

export default {
  name: "CompareButton",
  components: {
    BaseButton,
    CompareIcon,
  },
  computed: {
    ...mapGetters("hybridActions", ["selectedAdIds"]),
  },
  methods: {
    ...mapMutations("hybridActions", ["SET_COMPARE_MODAL_OPEN"]),
    onShowCompare() {
      if (this.selectedAdIds.length < 2) return;
      this.SET_COMPARE_MODAL_OPEN(true);
    },
  },
};
</script>
